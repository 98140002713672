
import { mmcAjax } from '@/common/js/mmcModule';

/**
 * 项目支持列表
 * @link https://qscwiki001.qingsongchou.com/pages/viewpage.action?pageId=93456846
*/
const getSupports = function (page) {
    const url = `${window.APIBasePath}mmc/user-center/support/list/${page}`;
    const debuger = false;
    if (debuger) {
        const project = require('./mock/list.json');
        return new Promise((resolve, reject) => {
            resolve(project)
        })
    }

    return mmcAjax({
        method: 'GET',
        url,
        crossDomain: true,
    })
}

export {
    getSupports,
}
