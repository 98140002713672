<template>
    <div class="supporters-warpper"
        v-infinite-scroll="loadMore"
        :infinite-scroll-disabled="loadBusy"
        infinite-scroll-distance="200"
    >
        <template v-if="isLoad && listData.length">
            <div class="label_title">我的支持</div>
            <ul class="list-container">
                <li class="cell-item"
                    v-for="(item, index) in listData"
                    :key="index"
                >
                    <a class="list_link" href="javascript:;" @click="toOrder(item.order_no)">
                        <div class="cell-lt">
                            <img v-lazy="item.project_thumb" class="project-avatar" />
                            <div class="project-info">
                                <span class="project-title mr-6">{{ item.title }}</span>
                                <span class="gary">{{ item.support_date }}</span>
                            </div>
                        </div>

                        <div class="cell-rt flex-col-space">
                            <span class="money mr-6 ">{{ item.amount/100 }} 元</span>
                            <span class="project-state gary">{{ item.project_status }}</span>
                        </div>
                    </a>
                </li>
            </ul>
        </template>

        <div v-if="isLoad && !listData.length">
            <img src="./img/not-data.png" class="not-data" />
            <span class="not-data-desc">暂无支持记录</span>
        </div>
    </div>
</template>

<script>
import Vue from 'vue';
import utils from '@/common/js/utils';
import { getSupports } from './api';
import { authChecker, shareCtrl } from '@/common/js/mmcModule';
import infiniteScroll from 'vue-infinite-scroll';
Vue.use(infiniteScroll);

const urlParams = utils.getRequestParams();
export default {
    name:'Supporters',
    data () {
        return {
            isRequestLoad: false,
            loadBusy: false,
            isLoad: false,

            listData: [],
            nextPage: true,
            pageIndex: 1,

            authChecker: false,
        }
    },
    created() {
        shareCtrl.init();
    },

    methods: {
        loadMore() {
            if (!this.nextPage) return;
            if (this.isRequestLoad) return;
            this.loadBusy = true;
            this.isRequestLoad = true;
            showLoading('加载中');

            setTimeout(() => {
                //检测登录
                if (this.authChecker) {
                    this.getSupportList();
                } else {
                    authChecker.check({ notneedphone: 1 }, () => {
                        this.getSupportList();
                        this.authChecker = true;
                    });
                }
            }, 500);
        },

        getSupportList() {
            const oldList = this.listData;
            let pageIndex = this.pageIndex;
            getSupports(pageIndex)
            .then(res => {
                hideLoading();

                const data = res.data;
                if (res.code == 0) {
                    let currentList = data.list;
                    const pageIndex = this.pageIndex;

                    if (currentList && currentList.length) {
                        currentList = this.filterStatus(currentList);
                        let newList = oldList.length > 0 ? oldList.concat(currentList) : currentList;
                        this.listData = newList;
                    }

                    if (pageIndex < data.page_total) {
                        this.pageIndex = ++this.pageIndex;
                        this.nextPage = true;
                    } else {
                        this.nextPage = false;
                    }
                } else {
                    // alertMsg(res.msg);
                }
                this.isLoad = true;
                this.loadBusy = false;
                this.isRequestLoad = false;
            })
            .catch(err => {
                hideLoading();
                this.loadBusy = false;
                this.isRequestLoad = false;
            });
        },

        filterStatus(listData) {
            return listData.map(item => {
                let project_avatar = "";
                if (item.images && item.images.length) {
                    let img = item.images[0]
                    if (img.indexOf('@!thumb') !== -1) {
                        project_avatar = `${img.split('@!')[0]}@!thumb.png`;
                    } else {
                        project_avatar = `${img}@!thumb.png`;
                    }
                    item.project_avatar = project_avatar;
                };

                let project_status = '支持成功';
                if(item.order_state === 1) {
                    project_status = '退款中';
                }
                if(item.order_state === 2) {
                    project_status = '退款成功';
                }
                 if(item.order_state === 3) {
                    project_status = '退款失败';
                }
                item['project_status'] = project_status;
                return item;
            })
        },

        toOrder(order_no) {
            this.$router.push({
                path: '/fund/my/order',
                query: { order_no }
            })
        }
    }
}
</script>

<style lang='less' scoped>
.supporters-warpper {
    padding: 30px 15px 15px;
}

.label_title {
    margin-bottom: 35px;
    font-size: 34px;
    font-weight: 700;
    color: #333333;
    line-height: 1;
    text-align: left;
}

.list-container {
    margin: 0;
    padding: 0;
}

.cell-item {
    list-style: none;
    margin-bottom: 35px;
    position: relative;
}

.list_link {
    display: flex;
    justify-content: space-between;
    span {
        display: block;
    }
    .gary {
        font-size: 13px;
        color: #AAAAAA;
        line-height: 1;
    }
    .mr-6 {
        margin-bottom: 6px;
    }
}


.project-state {
    text-align: right;
}

.cell-lt {
    display: flex;
    width: 74%;
    position: relative;
    padding-left: 55px;

    .project-avatar {
        display: block;
        width: 40px;
        height: 40px;
        max-width: 40px;
        overflow: hidden;
        border-radius: 50%;
        flex-shrink: 0;
        position: absolute;
        left: 0;
        top: 50%;
        transform: translateY(-50%);
    }

    .project-info {
        text-align: left;
        width: 100%;
    }

    .project-title {
        font-size: 16px;
        font-weight: 600;
        color: #333;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        width: 100%;
        display: block;
    }
}
.cell-rt {
    width: 24%;
    overflow: hidden;
    .money {
        font-size: 16px;
        font-weight: 600;
        color: #009BFF;
        text-align: right;
        line-height: 1;
        width: 100%;
    }
    span {
        display: block;
    }
}
.flex-col-space {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
}

.not-data {
    width: 200px;
    height: auto;
    display: block;
    margin: 0 auto 10px;
}

.not-data-desc {
    font-size: 17px;
    color: #333333;
    text-align: center;
    line-height: 54px;
}
</style>
